import React, { FC } from 'react';
import { Pane, Heading, defaultTheme, Text, Combobox, Popover, Button, Position, Icon } from 'evergreen-ui';
import { observer } from 'mobx-react';
import { IStageModel } from '../models/StageModel';
import NumericInput from './NumericInput';
import StageRoundRobin from './StageRoundRobin';
import { StageFormats, VisualizationStyle } from '../common/Constants';
import StageGroups from './StageGroups';
import StageElimination from './StageElimination';

const messages = (values:any[] = []) => ({
    roundI: `Round ${values[0]}`,
    matches: "Matches",
    groupsCount: `This stage has ${values[0]} groups.`,
    teamsPerGroup: `Each group has ${values[0]} teams.`,
    matchesPerTeam: `Each team will play ${values[0]} matches.`,
    totalMatches: `This stage will have a total of ${values[0]} matches.`,
    advancingTeams: "How many teams will advance to the next round?",
    noStageMatches: "No matches for this stage",
})
interface IStageProps {
    stage: IStageModel,
    showScores?: boolean,
    width?: number
}

const Stage: FC<IStageProps> = (props) => {
    
    const renderStage = () => {
        if (props.stage.format === StageFormats.ROUND_ROBIN) {
            return <StageRoundRobin stage={props.stage} width={150} />
        } else if (props.stage.format === StageFormats.SINGLE_ELIMINATION) {
            return <StageElimination stage={props.stage} showScores={false} 
                displayStyle={VisualizationStyle.Complete}/>
        } else if (props.stage.format === StageFormats.GROUPS) {
            return <StageGroups stage={props.stage} />
        }
        return <Text>Format is invalid</Text>;
    }

    const summaryContent = () => {
        const data = props.stage.summary;
        let summary = <Pane
            width={300}
            padding={10}>
            {props.stage.isGroup && <>
                <div>
                    <Icon icon='arrow-right' size={11} color={defaultTheme.colors.icon.muted}/>&nbsp;&nbsp;
                    <Text>{messages([props.stage.groupsCount]).groupsCount}</Text>
                </div>
                <div>
                    <Icon icon='arrow-right' size={11} color={defaultTheme.colors.icon.muted}/>&nbsp;&nbsp;
                    <Text>{messages([data.teamsPerGroup]).teamsPerGroup}</Text>
                </div>
            </>}
            <div>
                <Icon icon='arrow-right' size={11} color={defaultTheme.colors.icon.muted}/>&nbsp;&nbsp;
                <Text>{messages([data.matchesPerTeam]).matchesPerTeam}</Text>
            </div>
            <div>
                <Icon icon='arrow-right' size={11} color={defaultTheme.colors.icon.muted}/>&nbsp;&nbsp;
                <Text>{messages([data.totalMatches]).totalMatches}</Text>
            </div>
        </Pane>
        return summary;
    }

    return (
        <Pane elevation={1}>
            <Pane padding={16} borderBottom="muted" backgroundColor={defaultTheme.colors.background.tint2}>
                <Heading size={600}>{props.stage.name}</Heading>
            </Pane>
            <Pane margin={10}>
                <Combobox
                    initialSelectedItem={{ label: props.stage.format }}
                    items={[
                        StageFormats.ROUND_ROBIN,
                        StageFormats.SINGLE_ELIMINATION,
                        StageFormats.GROUPS,
                    ]}
                    onChange={selected => props.stage.setFormat(selected)}
                    selectedItem={props.stage.format}
                    />
            </Pane>
            <Pane display="flex">
                {renderStage()}
            </Pane>
            {props.stage.canHaveNext && 
            <Pane marginLeft={15} marginTop={15} display="flex" alignItems="center">
                <Text>{messages().advancingTeams}</Text>&nbsp;
                <NumericInput key={props.stage.id} value={props.stage.advancingTeams} width={50} 
                    minValue={props.stage.minAdvancingTeams} 
                    maxValue={props.stage.teams.length} 
                    onChange={(newValue:number) => props.stage.setAdvancingTeams(newValue)}/>
            </Pane>
            }
            <Pane marginTop={15} 
                textAlign="center" 
                padding={16} 
                backgroundColor={defaultTheme.colors.background.tint2}
                display="flex" justifyContent="space-between"
                alignItems="center">

                <Popover
                    position={Position.BOTTOM}
                    content={summaryContent()}>
                    <Button>Stage Summary</Button>
                </Popover>
            {props.stage.hasNext &&
                <Heading size={400}>Next stage created -&gt;</Heading>
            }
            {(!props.stage.canHaveNext || props.stage.advancingTeams < 2) &&
                <Heading size={400}>Last stage</Heading>
            }
            </Pane>
        </Pane>
    )
};

export default observer(Stage);