import React, { FC, useState, useRef } from 'react';
import { Pane, Text, IconButton, Avatar, defaultTheme } from 'evergreen-ui';
import { ITeamModel } from '../models/TeamModel';
import { observer } from 'mobx-react';

interface ITeamChipProps {
    team: ITeamModel,
    isDeletable?: boolean,
    onDelete?: Function,
    isEditable?: boolean,
    onEdit?: Function,
    isDraggable?: boolean,
}

const TeamChip: FC<ITeamChipProps> = (props) => {
    const [name, setName] = useState(props.team.name);
    const [editing, setEditing] = useState(false);
    const cellRef = useRef<HTMLDivElement>(null);

    const startEditing = () => {
        setEditing(true);
        if (cellRef && cellRef.current) {
            cellRef.current.focus();
        }
    };

    const doneEditing = () => {
        if (cellRef && cellRef.current) {
            cellRef.current.blur();
            if (props.onEdit) {
                props.onEdit(cellRef.current.innerText);
            }
            const newName = cellRef.current.innerText;
            setName(newName);
        }
        setEditing(false);
    };
    const cancelEditing = () => {
        if (cellRef && cellRef.current) {
            cellRef.current.innerText = name;
            cellRef.current.blur();
        }
        setEditing(false);
    };
    const onKeyDown = (e:any) => {
        if ( (e.keyCode === 13 || e.keyCode === 27 ) && 
            cellRef && 
            cellRef.current) {
                
            e.preventDefault();
            e.stopPropagation(); 
            if (e.keyCode === 13) {
                doneEditing();
            } else {
                cancelEditing();
            }
        }
    };
    let textCursor = 'default';
    if (props.isDraggable)
        textCursor = 'move'
    if (props.isEditable)
        textCursor = 'text'
    
    return (
        <Pane display="flex" 
            justifyContent="space-between"
            float="left"
            minWidth={120}
            backgroundColor={defaultTheme.palette.neutral.lightest}
            border='default'
            borderWidth={1}
            borderRadius={30}>
            <Avatar display="flex" flex="0 0 auto" name={name} size={30} marginTop={0} marginLeft={0} cursor={props.isDraggable ? "move" : 'default'}/>
            <Pane display="flex" flex="1 1 auto" alignItems="center" marginLeft={5} marginRight={props.isDeletable || props.isEditable ? 0 : 12}>
                <Text size={500} color="default" width="100%" wordWrap="revert" cursor={textCursor}>
                    <div 
                        ref={cellRef} 
                        contentEditable={props.isEditable} 
                        suppressContentEditableWarning={true} 
                        onFocus={startEditing}
                        onKeyDown={onKeyDown}
                        style={{width:'100%', paddingRight:'5px', whiteSpace:'nowrap'}}
                        onBlur={doneEditing}
                    >
                        {name}
                    </div>
                </Text>
            </Pane>
            {( props.isDeletable || props.isEditable ) && !editing && 
                <Pane flex="0 0 auto" height={30} display="flex">
                    {props.isEditable && <IconButton  width={17}  marginRight={props.isDeletable ? 2 : 5} icon="edit" appearance="minimal" onClick={startEditing}  />}
                    {props.isDeletable && props.onDelete && <IconButton  width={17} marginRight={5} icon="small-cross" appearance="minimal" onClick={() => props.onDelete && props.onDelete(props.team)} />}
            
                </Pane>
            }
            {props.isEditable && editing && 
                <Pane flex="0 0 auto" height={30} display="flex">
                    <IconButton marginLeft={5} width={17} icon="small-cross" appearance="minimal" onClick={cancelEditing}/>
                    <IconButton width={17} icon="small-tick" appearance="minimal" onClick={doneEditing}/>
                </Pane>
            }
            
        </Pane>
    )
};

export default observer(TeamChip);
