import React, { FC } from 'react';
import { Pane, TextInput, IconButton } from 'evergreen-ui';

interface INumericInputProps {
    value?: number,
    id?: string,
    placeholder?: string,
    width?: number,
    height?: number,
    minValue?: number,
    maxValue?: number,
    onChange?: Function,
}

const NumericInput: FC<INumericInputProps> = (props) => {

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (
            (newValue !== '-' && isNaN(newValue as any)) ||
            (props.minValue !== undefined && parseInt(newValue) < props.minValue) ||
            (props.maxValue !== undefined && parseInt(newValue) > props.maxValue) ||
            (newValue === '-' && props.minValue !== undefined && props.minValue >= 0)
        ) {
            return;
        }

        props.onChange && props.onChange(parseInt(newValue));
    }
    const increase = () => {
        let newValue = props.value ? props.value+1 : 1;
        if(props.maxValue !== undefined && newValue > props.maxValue) {
            newValue = props.maxValue;
        }
        if(props.minValue !== undefined && newValue < props.minValue) {
            newValue = props.minValue;
        }
        props.onChange && props.onChange(newValue);
    }
    const decrease = () => {
        let newValue = props.value ? props.value-1 : 0;
        
        if(props.minValue !== undefined && newValue < props.minValue) {
            return;
        }
        props.onChange && props.onChange(newValue);
    }
    
    return (
        <Pane display="flex" alignItems="center">
            <TextInput id={props.id} height={30} width={props.width || '100%'} 
                value={props.value !== undefined ? props.value+'' : ''} 
                onChange={onChange}/>
            <Pane marginLeft={-15}>
                <IconButton icon="small-plus" height={15} appearance="minimal" onClick={increase}/>
                <IconButton icon="small-minus" height={15} appearance="minimal" onClick={decrease}/>
            </Pane>
        </Pane>
    )
};

export default NumericInput;