import { types, Instance, getParent } from "mobx-state-tree";
import { StageModel, IStageModel } from "./StageModel";
import uuid from "uuid";
import { ITeamModel, TeamModel } from "./TeamModel";
import { IRootModel } from "./RootModel";
import { firestore } from "firebase";

export const CompetitionModel = types
    .model("CompetitionModel", {
        id: types.optional(types.identifier, () => uuid()),
        name: types.optional(types.string, "My Competition"),
        stages: types.array(StageModel)
    })
    .views(self => ({
        get teams(): ITeamModel[] {
            if (!self.stages || self.stages.length < 1 ) return [];
            return self.stages[0].teams;
        },
        getStageById(stageId: string): IStageModel|undefined {
            return self.stages.find((stage) => stage.id === stageId)
        },
        getStageByIndex(stageIndex: number): IStageModel|undefined {
            return self.stages[stageIndex];
        },
        get storage(): firestore.Firestore {
            const root: IRootModel = getParent(self,1);
            return root.storage;
        },
    }))
    .actions(self => ({
        addTeam(newTeam: ITeamModel) {
            if (!self.stages || self.stages.length < 1 ) return;
            self.stages[0].addTeam(newTeam);
        },
        deleteTeam(team: ITeamModel) {
            if (!self.stages || self.stages.length < 1 ) return;
            self.stages[0].deleteTeam(team);
        },
        sortTeams(from: number, to: number) {
            if (!self.stages || self.stages.length < 1 ) return;
            self.stages[0].sortTeams(from, to);
        },
        setTeams(newTeams:ITeamModel[]) {
            if (!self.stages || self.stages.length < 1 ) return;
            self.stages[0].setStageTeams(newTeams);
        },
        updateTeams(newTeamsNames: string[]) {
            const newTeams = newTeamsNames.filter(team => {
                //removes empty lines
                return team && team.trim()
            }).map(team => {
                return TeamModel.create({name: team})
            });
            this.setTeams(newTeams);
        },
        generateMatches() {
            if (!self.stages || self.stages.length < 1 ) return;
            self.stages[0].generateMatches();
        },
        addStage(newStage: IStageModel) {
            self.stages.push(newStage);
        },

        deleteStageByIndex(i: number) {
            if (i > self.stages.length) {
                return;
            }
            //deletes all stages after "i" (inclusive)
            self.stages.splice(i, self.stages.length - i);
        },

        deleteStageById(stageId: string) {
            let i;
            for (i = 0 ; i < self.stages.length ; i++){
                if (self.stages[i].id === stageId) {
                    break;
                }
            }
            if (i >= self.stages.length) {
                return;
            }
            this.deleteStageByIndex(i);
        },
        saveCompetition(deepSave=true) {
            const compRef = self.storage.collection('competitions').doc(self.id);
            
            const batch:firestore.WriteBatch = self.storage.batch();
            batch.set(compRef, {name: self.name});
            
            if (deepSave) {
                self.stages.map((stage) => stage.saveStage(deepSave, batch, compRef));
            }

            batch.commit()
            .then((s) => {
                //alert(`Batch saved ${self.name}`);
                console.log(s);
                
            })
            .catch((e) => {
                console.log(e);
                
            })
            return;
        },
    }));

export type ICompetitionModel = Instance<typeof CompetitionModel>