import React, { FC, useState } from 'react';
import { Pane, IconButton, SideSheet, Label, Textarea, Button, Heading } from 'evergreen-ui';
import { ReactSortable, Sortable, Store } from "react-sortablejs";
import TeamChip from './TeamChip';
import { ITeamModel, TeamModel } from '../models/TeamModel';
import { observer } from 'mobx-react';
import { useStore } from '../models/RootModel';

interface ITeamsBoxProps {
    teams: ITeamModel[],
    isEditable?: boolean,
    isSortable?: boolean,
    onSort?: Function,
    isDeletable?: boolean,
    onDelete?: Function,
    isInsertable?: boolean,
    onInsert?: Function,
}

const TeamsBox: FC<ITeamsBoxProps> = (props) => {
    const { competition } = useStore()
    const [showDrawer, setShowDrawer] = useState(false);
    const [teamsStr, setTeamsStr] = useState(competition.teams.map((team) => team.name).join('\n'));
    

    const onChange = (evt: Sortable.SortableEvent, sortable: Sortable | null, store: Store) => {
        if (props.onSort) {
            props.onSort(evt.oldIndex, evt.newIndex);
        }
    }
    
    const changeTeamName = (team:ITeamModel, newName:string) => {
        team.setName(newName);
        setTeamsStr(competition.teams.map((team) => team.name).join('\n'));
    }
    const updateTeams = () => {
        const teamsNames = teamsStr.split("\n");
        competition.updateTeams(teamsNames);
        setShowDrawer(false);
    }
    
    return (
        <Pane display="flex" 
            padding={4}
            border='default'
            elevation={2}
            margin={20}
        >
            <ReactSortable
                list={props.teams.map((t: ITeamModel) => ({id: t.id, name: t.name, el: t}))}
                setList={() => {}}
                onEnd={onChange}
                animation={400}
                delay={2}
                disabled={!props.isSortable}
            >
                {competition.teams.map( team => {
                    return <Pane margin={4} key={team.id} float="left" justifyContent='space-between'>
                        <TeamChip 
                            team={team} 
                            isEditable={props.isEditable} 
                            onEdit={(newName: string) => changeTeamName(team, newName)}
                            isDeletable={props.isDeletable}
                            onDelete={(team:any) => props.onDelete && props.onDelete(team)}
                            isDraggable={props.isSortable}/>
                    </Pane>
                })}
            </ReactSortable>
            {props.isInsertable && <IconButton icon="add" appearance="minimal" 
                onClick={() => props.onInsert && props.onInsert(TeamModel.create({name: 'New Team'}))}/>}
            {props.isEditable && <IconButton icon="edit" appearance="minimal" onClick={() => setShowDrawer(true)}/>}
            {props.isEditable && <SideSheet
                isShown={showDrawer}
                onCloseComplete={() => setShowDrawer(false)}
                width={400}
                shouldCloseOnOverlayClick={false}
                preventBodyScrolling
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column',
                    }}>
                <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                    <Pane padding={16}>
                        <Heading size={600}>Teams</Heading>
                    </Pane>
                </Pane>
                <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                    <Label
                        htmlFor="teams"
                        marginBottom={4}
                        display="block"
                    >
                        Enter the competition's teams (one per line):
                    </Label>
                    <Textarea
                        id="teams"
                        placeholder="Teams"
                        minHeight={200}
                        height='80%'
                        onChange={(e:any) => setTeamsStr(e.target.value)}
                        value={teamsStr}
                        marginBottom={16}
                    />
                    <Button marginRight={8} iconBefore="cross" 
                        onClick={() => setShowDrawer(false)}>
                        Cancel
                    </Button>
                    <Button appearance="primary" iconAfter="tick" 
                        onClick={() => updateTeams()}>
                        Update
                    </Button>
                </Pane>
            </SideSheet>}
        </Pane>
    )
};

export default observer(TeamsBox);