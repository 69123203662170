export const StageFormats = {
    ROUND_ROBIN: 'Round Robin',
    SINGLE_ELIMINATION: 'Single Elimination',
    GROUPS: 'Groups'
}
export enum VisualizationStyle {
    Compact,
    Complete
}

export const firebaseConfig = {
    apiKey: "AIzaSyCxbl3vxncY2MZxtYIxB51FozptvNuNy-A",
    authDomain: "ownliga.firebaseapp.com",
    databaseURL: "https://ownliga.firebaseio.com",
    projectId: "ownliga",
    storageBucket: "ownliga.appspot.com",
    messagingSenderId: "540875792973",
    appId: "1:540875792973:web:3331eee065f88a66d7b204",
    measurementId: "G-43J7P0JSR6"
};
