import React, { FC, useState } from 'react';
import { Pane, Heading, Label } from 'evergreen-ui';
import { observer } from 'mobx-react';
import { IStageModel } from '../models/StageModel';
import { ITeamModel } from '../models/TeamModel';
import TeamChip from './TeamChip';
import NumericInput from './NumericInput';
import RounRobinMatches from './RounRobinMatches';

interface IStageGroupsProps {
    stage: IStageModel,
    showScores?: boolean,
    width?: number
}

const StageGroups: FC<IStageGroupsProps> = (props) => {
    const [selectedGroup, setSelectedGroup] = useState(0);
    return (
        <Pane padding={16} width='100%'>
            <Pane marginBottom={16} display='flex' alignItems='center'>
                <Label
                    htmlFor='groupsCount'
                    marginBottom={4}
                    display="block">
                    Number of groups:
                    </Label>&nbsp;&nbsp;
                <NumericInput id='groupsCount' 
                    value={props.stage.groupsCount} 
                    maxValue={Math.floor(props.stage.teams.length/2)}
                    minValue={1}
                    onChange={props.stage.setGroupsCount}
                    width={50}/>
            </Pane>
            <Pane display="flex" overflowX='auto' marginBottom={16}>
                {props.stage.groups.map((group: Array<ITeamModel>, index:number) => {
                    return <Pane display="flex" flexDirection='column' borderRadius={16}
                        background={selectedGroup===index ? 'redTint' : undefined}
                        paddingX={4} paddingY={8} id={index+''} cursor='pointer'
                        onClick={() => setSelectedGroup(index)}
                        key={index}> 
                        <Heading size={300} textAlign="center">Group {index+1}</Heading>
                        {group.map((team: ITeamModel) => {
                            return <Pane margin={2} key={team.id} >
                                <TeamChip team={team} key={team.id}/>
                            </Pane>
                        })}
                    </Pane>
                })}
            </Pane>
            <RounRobinMatches
                matches={props.stage.matches[selectedGroup]} 
                showScores={props.showScores}
                width={props.width} 
                matchesTitle={'Group ' + (selectedGroup+1) + ' Matches'}/>
        </Pane>
    )
};

export default observer(StageGroups);