import React, { FC } from 'react';
import { Pane, Icon, defaultTheme, Avatar, Text, Badge, Pill } from 'evergreen-ui';
import { observer } from 'mobx-react';
import { IMatchModel } from '../models/MatchModel';
import NumericInput from './NumericInput';

interface IMatchProps {
    match: IMatchModel,
    showScores?: boolean,
    width?: number,
    showMatchNumber?: boolean,
    verticalDisplay?: boolean,
}

const leftAvatarTeam = (props:IMatchProps, teamName: string, teamScore: number|undefined, setScore: Function) => {
    return <>
        <Avatar display="flex" flex="0 0 auto" name={teamName} size={30} marginTop={0} marginLeft={0} />
        <Pane display="flex" flex="1 1 auto" alignItems="center" marginLeft={5} marginRight={25}>
            <Text size={500} color="default" width={props.width || "100%"} wordWrap="revert"
                whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {teamName}
            </Text>
        </Pane>
        {props.showScores && <NumericInput width={50} minValue={0} 
            value={teamScore} 
            onChange={setScore} />}
    </>
}
const rightAvatarTeam = (props:IMatchProps, teamName: string, teamScore: number|undefined, setScore: Function) => {
    return <>
        {props.showScores && <NumericInput width={50} minValue={0} 
            value={teamScore} 
            onChange={setScore} />}
        <Pane display="flex" flex="1 1 auto" alignItems="center" marginLeft={15} marginRight={5}>
            <Text size={500} color="default" width={props.width || "100%"} wordWrap="revert" textAlign="right" 
                whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {teamName}
            </Text>
        </Pane>
        <Avatar display="flex" flex="0 0 auto" name={teamName} size={30} marginTop={0} marginLeft={0} />
    </>
}

const Match: FC<IMatchProps> = (props) => {
    return (
            <Pane display="flex" alignItems="center">
                <Pane display="flex" 
                    justifyContent="space-between"
                    flexDirection={props.verticalDisplay ? 'column' : 'row'}
                    float="left"
                    minWidth={120}
                    backgroundColor={defaultTheme.palette.neutral.lightest}
                    border='default'
                    borderWidth={1}
                    borderRadius={props.verticalDisplay ? 0 : 30}
                    borderBottomLeftRadius={props.verticalDisplay ? 15 : 30}
                    borderTopLeftRadius={props.verticalDisplay ? 15 : 30}
                    alignItems={!props.verticalDisplay ?  'center' : ''}>
                    
                    {!props.verticalDisplay ? 
                    <>
                        {props.showMatchNumber && 
                            <Pane>
                                <Pill display="inline-flex" margin={8}>Match {props.match.matchNumber}</Pill>
                            </Pane>
                        }
                        <Pane display='flex' alignItems='center'>
                            {leftAvatarTeam(props, props.match.homeTeamName, props.match.homeTeamScore, props.match.setHomeTeamScore)}
                        </Pane>
                        <Icon icon="cross" size={20} color={defaultTheme.colors.icon.muted}/>
                        <Pane display='flex'>
                            {rightAvatarTeam(props, props.match.awayTeamName, props.match.awayTeamScore, props.match.setAwayTeamScore)}
                        </Pane>
                    </>
                    : 
                    <>
                        <Pane display='flex' >
                            {leftAvatarTeam(props, props.match.homeTeamName, props.match.homeTeamScore, props.match.setHomeTeamScore)}
                        </Pane>
                        
                        {props.showMatchNumber && <Badge>Match {props.match.matchNumber}</Badge>}

                        <Pane display='flex'>
                            {leftAvatarTeam(props, props.match.awayTeamName, props.match.awayTeamScore, props.match.setAwayTeamScore)}
                        </Pane>
                    </>}
                </Pane>
            </Pane>
    )
};

export default observer(Match);