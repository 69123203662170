import { types, Instance, detach, cast, getParent, getSnapshot } from "mobx-state-tree";
import uuid from "uuid";
import { TeamModel, ITeamModel } from "./TeamModel";
import { MatchModel } from "./MatchModel";
import { basicRoundRobinV1 } from "../common/RoundRobinUtil";
import { basicElimination } from "../common/EliminationUtils";
import { StageFormats } from "../common/Constants";
import { ICompetitionModel } from "./CompetitionModel";
import { generateTeamsFromPreviousStage } from "../common/Utils";
import { firestore } from "firebase";

export const StageModel = types
    .model("StageModel", {
        id: types.optional(types.identifier, () => uuid()),
        index: types.optional(types.integer, 0),
        name: types.string,
        format: types.optional(types.enumeration('Format', [
            StageFormats.ROUND_ROBIN, 
            StageFormats.SINGLE_ELIMINATION, 
            StageFormats.GROUPS
        ]), StageFormats.ROUND_ROBIN),
        teams: types.array(TeamModel),
        matches: types.array(types.array(types.array(MatchModel))),
        advancingTeams: types.optional(types.integer, 0),
        groupsCount: types.optional(types.integer, 1),
    })
    .views(self => ({
        get isGroup(): boolean {
            return self.format === StageFormats.GROUPS;
        },
        get canHaveNext() {
            if (self.format === StageFormats.SINGLE_ELIMINATION) {
                return false;
            }
            return true;
        },
        get hasNext() {
            return this.canHaveNext && self.advancingTeams > 1
        },
        get minAdvancingTeams() {
            if (this.isGroup) {
                return self.groupsCount;
            }
            return 0;
        }, 
        get numberOfRounds() {
            return self.matches.length
        },
        get numberOfMatchesPerTeam() {
            return self.teams.length % 2 === 0 ? this.numberOfRounds : this.numberOfRounds-1;
        },
        get numberOfMatches() {
            return this.summary.totalMatches;
        },

        get teamsIds() {
            return self.teams ? 
                self.teams.map( 
                    (t:ITeamModel) => t.id) : 
                    [];
        },
        get groups() {
            if (!self.groupsCount) return [];
            let groups:Array<Array<ITeamModel>> = [];
            for (let i=0 ; i<self.teams.length ; i++){
                if (i < self.groupsCount) {
                    groups.push([]);
                }
                groups[i % self.groupsCount].push(self.teams[i]);
            }
            return groups;
        },
        get summary() {
            if (self.format === StageFormats.SINGLE_ELIMINATION) {
                const rounds = Math.log2(self.teams.length);
                const nMatches = self.matches && self.matches[0] ? self.matches[0].length : 0;
                return {
                    matchesPerTeam: 'at least 1 and up to ' + nMatches,
                    totalMatches: Math.pow(2, rounds) - 1
                }
            } else if (self.format === StageFormats.ROUND_ROBIN) {
                const matchesPerTeam: number = (self.teams.length-1);
                return {
                    matchesPerTeam: matchesPerTeam,
                    totalMatches: ((self.teams.length/2)*(self.teams.length-1)),
                    maxMatches: matchesPerTeam,
                }
            } else if (self.format === StageFormats.GROUPS) {
    
                const teamsInFullGroup = Math.ceil(self.teams.length/self.groupsCount);
                const teamsInNotFullGroup = teamsInFullGroup - 1;
                let totalMatchesInFullGroup = ((teamsInFullGroup/2)*(teamsInFullGroup-1));
                let totalMatchesInNotFullGroup = ((teamsInNotFullGroup/2)*(teamsInNotFullGroup-1));
    
                let fullGroups = self.groupsCount;
                let notFullGroups = 0;
                if (self.teams.length % self.groupsCount !==0) {
                    fullGroups = self.teams.length % self.groupsCount;
                    notFullGroups = self.groupsCount - fullGroups;
                }

                const totalMatches = (totalMatchesInFullGroup*fullGroups) + (totalMatchesInNotFullGroup*notFullGroups);
                let matchesPerTeamInFullGroup = teamsInFullGroup-1;
    
                let teamsPerGroup:string|number = teamsInFullGroup;
                let matchesPerTeam:string|number = matchesPerTeamInFullGroup;
                if (notFullGroups !== 0) {
                    teamsPerGroup =  teamsPerGroup + " (" + (teamsPerGroup - 1) + ")";
                    matchesPerTeam = matchesPerTeam + " (" + (matchesPerTeam - 1) + ")";
                }
                return {
                    teamsPerGroup: teamsPerGroup,
                    matchesPerTeam: matchesPerTeam,
                    totalMatches: totalMatches,
                    maxMatches: matchesPerTeam,
                }
            }
            return {};
        },

        get storage(): firestore.Firestore {
            const competition: ICompetitionModel = getParent(self,1);
            return competition.storage;
        },
    }))
    .actions(self => ({
        addTeam(newTeam:ITeamModel) {
            detach(self.matches);
            self.matches.clear();
            self.matches.splice(0, self.matches.length);
            self.teams.push(newTeam);
            this.fixStageAndTrickleChanges();
        },
        deleteTeam(team: ITeamModel) {
            detach(self.matches);
            self.matches.clear();
            self.matches.splice(0, self.matches.length);
            detach(team);
            this.fixStageAndTrickleChanges();
        },
        sortTeams(from: number, to: number) {
            detach(self.matches);
            self.matches.clear();
            self.matches.splice(0, self.matches.length);
            //updates the position of the team
            const elem = self.teams[from];
            detach(elem)
            self.teams.splice(to, 0, elem);

            this.fixStageAndTrickleChanges();
        },
        setStageTeams(newTeams:ITeamModel[], fromFix:boolean=false) {
            detach(self.matches);
            self.matches.clear();
            self.matches.splice(0, self.matches.length);
            detach(self.teams);
            self.teams.clear();
            self.teams.splice(0, self.teams.length);
            self.teams = cast(newTeams);
            if (!fromFix) {
                this.fixStageAndTrickleChanges();
            }

        },

        generateMatches() {
            detach(self.matches);
            self.matches.clear();   
            self.matches.splice(0, self.matches.length);

            if (!self.teams || self.teams.length === 0) return;
            
            if (self.format === StageFormats.ROUND_ROBIN) {
                self.matches.push(basicRoundRobinV1(self.teamsIds, false, 0));
            } else if (self.format === StageFormats.GROUPS) {
                const groups = self.groups;
                groups.map((group, index) => {
                    self.matches[index] = basicRoundRobinV1(group.map((team) => team.id), false, 0);
                    return self.matches;
                });
            } else if (self.format === StageFormats.SINGLE_ELIMINATION) {
                self.matches.push(basicElimination(self.teamsIds, 0));

            } else {
                console.error('Can\'t generate matches. Invalid format.');
            }
        },

        setFormat(newFormat: string, fromFix:boolean=false) {
            self.format = newFormat;
            if (!fromFix) {
                this.fixStageAndTrickleChanges();
            }
        },
        setGroupsCount(count:number, fromFix:boolean=false) {
            self.groupsCount = count;
            if (!fromFix) {
                this.fixStageAndTrickleChanges();
            }
        },
        setAdvancingTeams(advTeams: number, fromFix:boolean=false) {
            self.advancingTeams = advTeams;
            if (!fromFix) {
                this.fixStageAndTrickleChanges();
            }
        },

        fixStageAndTrickleChanges() {
            const countTeamsInStage = self.teams.length;
            if (!countTeamsInStage) return;

            const parent:ICompetitionModel = getParent(self, 2);
            const nextStage = self.index+1 < parent.stages.length ? parent.stages[self.index+1] : undefined;
            const previousStage = self.index > 0 ? parent.stages[self.index-1] : undefined;
            const maxTeams = previousStage ? previousStage.advancingTeams : parent.teams.length;
            while (self.teams.length > maxTeams) {
                this.deleteTeam(self.teams[self.teams.length-1]);
            }

            if (previousStage && previousStage.advancingTeams > self.teams.length) {
                const newTeams = generateTeamsFromPreviousStage(previousStage);
                this.setStageTeams(newTeams, true);
            }

            if (self.format === StageFormats.GROUPS) {
                if (self.teams.length < 4 && self.groupsCount > 1) {
                    this.setGroupsCount(1, true);
                } else if (self.groupsCount > Math.floor(self.teams.length/2)) {
                    this.setGroupsCount(Math.floor(self.teams.length/2), true);
                }
            } else if (self.format === StageFormats.SINGLE_ELIMINATION) {
                this.setGroupsCount(1, true);
                this.setAdvancingTeams(1, true);
            } else {
                if (self.groupsCount > 1) {
                    this.setGroupsCount(1, true);
                }
            }

            if (self.advancingTeams < self.groupsCount) {
                this.setAdvancingTeams(self.groupsCount, true);
                if (nextStage) {
                    nextStage.fixStageAndTrickleChanges();
                }
            } else if (self.advancingTeams > self.teams.length) {
                //if the advancing teams is higher thant the number of teams, re-sets the advTeams
                this.setAdvancingTeams(self.teams.length, true);
                if (nextStage) {
                    nextStage.fixStageAndTrickleChanges();
                }
            } else if (self.advancingTeams < 2 && nextStage) {
                //if the number if advancing teams is smaller than 2 and there are stages after, removes them
                parent.deleteStageByIndex(self.index+1);
            } else if (self.advancingTeams > 1 && !nextStage) {
                //if advancing 2 or more, and there is no next stage, creates it
                parent.addStage(StageModel.create({
                    name: "Stage "+ (self.index+2),
                    teams: generateTeamsFromPreviousStage(self),
                    format: StageFormats.ROUND_ROBIN,
                    index: self.index+1,    
                    groupsCount: 1,
                    advancingTeams: 1,
                }));
                parent.stages[self.index+1].generateMatches();
            } else if (self.advancingTeams > 1 && nextStage) {
                nextStage.fixStageAndTrickleChanges();
            }

            this.generateMatches();
        },

        saveMatches(batch:firestore.WriteBatch, stageRef: firestore.DocumentReference, matchesSnap: any) {
            self.matches.forEach((group, gi) => {
                const groupsRef = stageRef.collection('groups').doc(gi+'');
                group.forEach((round, ri) => {
                    const roundRef = groupsRef.collection('rounds').doc(ri+'');
                    const m:any = {};
                    round.forEach((match, mi) => {
                        m[mi] = ({
                            matchNumber: match.matchNumber,
                            homeTeam: match.homeTeam.id,
                            homeTeamScore: match.homeTeamScore || -1,
                            awayTeam: match.awayTeam.id,
                            awayTeamScore: match.awayTeamScore || -1,
                        })
                    });
                    batch.set(roundRef, m)
                });
            });
            return;
        },
        saveTeams(batch:firestore.WriteBatch, stageRef: firestore.DocumentReference) {
            self.teams.map(team => team.storeTeam(batch, stageRef))
            return;
        },
        saveStage(deepSave=true, batch:firestore.WriteBatch, compRef: firestore.DocumentReference) {
            const stageRef = compRef.collection('stages').doc(self.index+'');
            const modelSnapshot = {...getSnapshot(self)};

            const thisBatch = batch || self.storage.batch();

            if (deepSave) {
                this.saveMatches(thisBatch, stageRef, modelSnapshot.matches);
                this.saveTeams(thisBatch, stageRef);
            }

            delete modelSnapshot.matches;
            delete modelSnapshot.teams;

            thisBatch.set(stageRef, modelSnapshot);

            if (!batch) {
                thisBatch.commit();
            }
            return;
        },
    }));

export type IStageModel = Instance<typeof StageModel>
