import React, { FC, useState } from 'react';
import { Pane, Tablist, Tab } from 'evergreen-ui';
import { observer } from 'mobx-react';
import TeamsBox from './TeamsBox';
import { ICompetitionModel } from '../models/CompetitionModel';
import { ITeamModel, TeamModel } from '../models/TeamModel';
import { generateTeamName } from '../common/Utils';
import Stage from './Stage';


interface ICompetitionCreationProps {
    competition: ICompetitionModel
}

const CompetitionCreation: FC<ICompetitionCreationProps> = (props) => {
    const [selectedStage, setSelectedStage] = useState(0);

    return (
            <Pane>
                <Pane marginBottom={15}>
                    <TeamsBox teams={props.competition.teams} 
                        isEditable={true} 
                        onDelete={(team:ITeamModel) => props.competition.deleteTeam(team)}
                        isDeletable={true}
                        isInsertable={true} 
                        onInsert={() => props.competition.addTeam(TeamModel.create({name: generateTeamName()}))}
                        isSortable={true}
                        onSort={(from:number, to:number) => props.competition.sortTeams(from, to)}
                        />
                </Pane>
                <Tablist marginBottom={15}>
                    {props.competition.stages.map((stage, index) => {
                        return <Tab 
                                isSelected={index === selectedStage} 
                                key={stage.id}
                                id={stage.id} 
                                onSelect={() => setSelectedStage(index)}>
                            {stage.name}
                        </Tab>
                    })}
                </Tablist>
                <Stage stage={props.competition.stages[selectedStage]} width={150} />
            </Pane>
    )
};

export default observer(CompetitionCreation);
