import { IMatchModel as IMatchModelV0, MatchModel as  MatchModelV0} from "../models/MatchModel";
import { IMatchModel, MatchModel } from "../models/MatchModel";

const BYE = "-1";

/**
 * basicRoundRobin takes the number of teams and a boolean if the matches
 * inside each round should be shuffled.
 * */
export const basicRoundRobin = (teams: string[], shuffleMatchesInRound: boolean, lastMatchNumber: number):any => {

    let nTeams = teams.length
    if ( nTeams < 2 ) return [];

    if (nTeams % 2 === 1){
        teams.push(BYE);
        nTeams++;
    }

    const nRounds = nTeams - 1;
    const matchesPerRound = nTeams / 2;

    let matches:IMatchModelV0[][] = [];
    let floatTable:string[] = [];
    for (let r = 0 ; r < nRounds ; r++) {
        let roundMatches:IMatchModelV0[] = [];
        if (r === 0){
            const incr = nTeams - 3;
            let j = 0;
            for (let i = 0 ; i < nTeams - 1 ; i++){
                floatTable[j] = teams[i];
                j += incr;
                j = j % (nTeams - 1);
            }
            floatTable[nRounds] = teams[nRounds];
        } else {
            const tempTeam = floatTable[nTeams - 2];
            for (let i = nTeams - 2 ; i > 0 ; i--){
                floatTable[i] = floatTable[i - 1];
            }
            floatTable[0] = tempTeam;
        }

        let matchOrder = matchPosition(matchesPerRound, shuffleMatchesInRound);// randomize(matchesPerRound);
        let kr = 0;
        let roundMatchNumber = matchOrder[0];
        let colorFlag = false;


        while(true){
            if (roundMatchNumber === 0) {
                colorFlag = ((r + 1) % 2) === 0;
            } else {
                colorFlag = (roundMatchNumber % 2) === 0;
            }
            const t2:number = colorFlag ? roundMatchNumber : nRounds - roundMatchNumber;
            const t1:number = colorFlag ? nRounds - roundMatchNumber : roundMatchNumber;

            roundMatches.push(MatchModelV0.create({
                homeTeam: floatTable[t1], 
                awayTeam:floatTable[t2],
                matchNumber: ++lastMatchNumber,
            }))
            if (++kr < matchesPerRound)
                roundMatchNumber = matchOrder[kr];
            else
                break;

        }
        matches[r] = roundMatches;
    }
    return matches;
};

export const basicRoundRobinV1 = (teams: string[], shuffleMatchesInRound: boolean, lastMatchNumber: number):any => {

    let nTeams = teams.length
    if ( nTeams < 2 ) return [];

    if (nTeams % 2 === 1){
        teams.push(BYE);
        nTeams++;
    }

    const nRounds = nTeams - 1;
    const matchesPerRound = nTeams / 2;

    let matches:IMatchModel[][] = [];
    let floatTable:string[] = [];
    for (let r = 0 ; r < nRounds ; r++) {
        let roundMatches:IMatchModel[] = [];
        if (r === 0){
            const incr = nTeams - 3;
            let j = 0;
            for (let i = 0 ; i < nTeams - 1 ; i++){
                floatTable[j] = teams[i];
                j += incr;
                j = j % (nTeams - 1);
            }
            floatTable[nRounds] = teams[nRounds];
        } else {
            const tempTeam = floatTable[nTeams - 2];
            for (let i = nTeams - 2 ; i > 0 ; i--){
                floatTable[i] = floatTable[i - 1];
            }
            floatTable[0] = tempTeam;
        }

        let matchOrder = matchPosition(matchesPerRound, shuffleMatchesInRound);// randomize(matchesPerRound);
        let kr = 0;
        let roundMatchNumber = matchOrder[0];
        let colorFlag = false;


        while(true){
            if (roundMatchNumber === 0) {
                colorFlag = ((r + 1) % 2) === 0;
            } else {
                colorFlag = (roundMatchNumber % 2) === 0;
            }
            const t2:number = colorFlag ? roundMatchNumber : nRounds - roundMatchNumber;
            const t1:number = colorFlag ? nRounds - roundMatchNumber : roundMatchNumber;

            roundMatches.push(MatchModel.create({
                homeTeam: floatTable[t1], 
                awayTeam:floatTable[t2],
                matchNumber: ++lastMatchNumber,
            }))
            if (++kr < matchesPerRound)
                roundMatchNumber = matchOrder[kr];
            else
                break;

        }
        matches[r] = roundMatches;
    }
    return matches;
};

/*
* Returns an array of shuffled positions
* */
const matchPosition = (n:number, random:boolean) => {
    let deck = [];
    let shuffle = [];
    for (let i = 0 ; i < n ; i++) {
        deck.push(i);
    }

    if (!random) return deck.slice();

    for (let i = n-1 ; i >=0 ; i--) {
        const r = Math.floor(Math.random() * (i+1));//random between 0 and n
        shuffle[i] = deck[r];
        deck[r] = deck[i];
    }
    return shuffle.slice();
};


/*
* Validates that the generated matches are valid
* - no more than 2 consecutive home or away matches.
* - max 1 bye per team
* */
export const validateMatches = (teams: Array<any>, matches: Array<Array<IMatchModel>>) => {
    const consecutive = teams.reduce((o, val) => { 
        o[val.id] = 0; 
        return o; 
    }, {});

    const byes = new Map();

    for (let i = 0 ; i < matches.length ; i++) {
        for (let j = 0 ; j < matches[i].length ; j++) {
            const m = matches[i][j];
            const homeId = m.homeTeam.id;
            const awayId = m.awayTeam.id

            consecutive[homeId] = consecutive[homeId]+1; 
            consecutive[awayId] = consecutive[awayId]-1;

            if (consecutive[homeId] > 2 ){
                console.error('Too many consecutive HOME matches for one team', homeId, consecutive[homeId]);
                return false;
            }
            if (consecutive[awayId] < -2 && awayId !== '-1'){
                console.error('Too many consecutive AWAY matches for one team', awayId, consecutive[awayId]);
                return false;
            }
            if (homeId === '-1') {
                if (byes.has(awayId)) {
                    console.error('A team is playing BYE more than once.', awayId);
                    return false;
                }
                byes.set(awayId, true);
            }
            if (awayId === '-1') {
                if (byes.has(homeId)) {
                    console.error('A team is playing BYE more than once.', homeId);
                    return false;
                }
                byes.set(homeId, true);
            }

        }
    }
    return true;
};
