import Duel from 'duel';
import { MatchModel, IMatchModel } from '../models/MatchModel';

export const basicElimination = (teams: string[], lastMatchNumber: number):any => {

    const nTeams = teams.length;
    if ( nTeams < 2 ) return [];
    if (nTeams < 3) {
        return [
            [
                MatchModel.create({
                    homeTeam: teams[0],
                    awayTeam: teams[1],
                    matchNumber: ++lastMatchNumber,
                })
            ]
        ]
    }
    if (nTeams < 4) {
        const matches = [
            [
                MatchModel.create({
                    homeTeam: teams[0],
                    awayTeam: -1,
                    matchNumber: ++lastMatchNumber,
                }),
                MatchModel.create({
                    homeTeam: teams[1],
                    awayTeam: teams[2],
                    matchNumber: ++lastMatchNumber,
                })
            ]
        ]
        matches.push(
            [
                MatchModel.create({
                    homeTeam: teams[0],
                    awayTeam: matches[0][1].id,
                    matchNumber: ++lastMatchNumber,
                }),
            ]);
        return matches;
    }

    const duel = new Duel(nTeams, {short: true});
    const duelMatches = duel.matches;
    let matches:IMatchModel[][] = [];

    duelMatches.forEach( (duelEntry: any) => {
        if (!matches) {
            matches = []
        }
        const duelRound = duelEntry.id.r;
        const duelMatch = duelEntry.id.m;
        const duelHome = duelEntry.p[0];
        const duelAway = duelEntry.p[1];

        
        if (!matches[duelRound-1]) {
            matches.push([]);
        }

        let homeTeam;
        const parent = (duelMatch*2)-2
        if (duelHome === 0) {
            homeTeam = matches[duelRound-2][parent].id;
        } else if (duelHome === -1) {
            homeTeam = -1; //bye id
        }  else {
            homeTeam = teams[duelHome-1];
        }

        let awayTeam;
        if (duelAway === 0) {
            awayTeam = matches[duelRound-2][parent+1].id;
        } else if (duelAway === -1) {
            awayTeam = -1; //bye id
        } else {
            awayTeam = teams[duelAway-1];
        }

        const match = MatchModel.create({
            homeTeam: homeTeam,
            awayTeam: awayTeam,
            matchNumber: ++lastMatchNumber,
        });
        matches[duelRound-1].push(match)
    });
    return matches;
};

export const getRoundName = (nMatches:number) => {
    if (nMatches === 1)
        return "Final";
    else if (nMatches === 2)
        return "Semi-Finals";
    else if (nMatches === 4)
        return "Quarter-Finals";

    return "Round of " + nMatches;

};