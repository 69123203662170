import { TeamModel, ITeamModel } from "../models/TeamModel";
import { StageFormats } from "./Constants";

export const generateTeamsModels = (n:number) => {
    return generateTeamsNames(n).map((name) => {
        return TeamModel.create({name: name})
    });
}

export const generateTeamsNames = (n:number) => {
    const teams = [];
    for( let i=0 ; i<n ; i++ ) {
        teams.push(generateTeamName())
    };
    return teams;
}

export const generateTeamsObjects = (n:number) => {
    const teams = [];
    for( let i=0 ; i<n ; i++ ) {
        teams.push({name: generateTeamName()})
    };
    return teams; 
}

export const generateTeamName = () => {
    const nAdjective = Math.floor((Math.random() * (adjectives.length-1)));
    const nAnimal = Math.floor((Math.random() * (animals.length-1)));
    return adjectives[nAdjective] + " " + animals[nAnimal];
};

export const generateTeamsFromPreviousStage = (prevStage: any): ITeamModel[] => {
    if (!prevStage) return [];
    const teams:ITeamModel[] = [];

    if (prevStage.format === StageFormats.ROUND_ROBIN) {
        while(teams.length < prevStage.advancingTeams) {
            teams.push(TeamModel.create({name: "Position " +(teams.length+1) + " in " + prevStage.name}));
        }
    } else if (prevStage.format === StageFormats.GROUPS) {
        const nGroups = prevStage.groupsCount;
        const best = Math.floor(prevStage.advancingTeams/nGroups);
        const teamsLeft = prevStage.advancingTeams % nGroups;
        let i = 0;
        let pos = 0;
        while (teams.length < best * nGroups) {
            if (teams.length % nGroups === 0) pos++;
            i = (teams.length % nGroups) + 1;
            teams.push(TeamModel.create({name: "Position " + pos + " in Group " + i}));
        }
        i = 1;
        while (i <= teamsLeft) {
            teams.push(TeamModel.create({name: "Position " +(best+1) + " Team " + i}));
            i++;
        }
    }
    return teams;
}

export const suggestGroupCount = (nTeams:number) => {
    const c = Math.floor(Math.log(nTeams));
    return c;
}

const adjectives = [
    'Adorable',
    'Adventurous',
    'Aggressive',
    'Agreeable',
    'Alert',
    'Alive',
    'Amused',
    'Angry',
    'Annoyed',
    'Annoying',
    'Anxious',
    'Arrogant',
    'Ashamed',
    'Attractive',
    'Average',
    'Awful',
    'Bad',
    'Beautiful',
    'Better',
    'Bewildered',
    'Black',
    'Bloody',
    'Blue',
    'Blue-eyed',
    'Blushing',
    'Bored',
    'Brainy',
    'Brave',
    'Breakable',
    'Bright',
    'Busy',
    'Calm',
    'Careful',
    'Cautious',
    'Charming',
    'Cheerful',
    'Clean',
    'Clear',
    'Clever',
    'Cloudy',
    'Clumsy',
    'Colorful',
    'Combative',
    'Comfortable',
    'Concerned',
    'Condemned',
    'Confused',
    'Cooperative',
    'Courageous',
    'Crazy',
    'Creepy',
    'Crowded',
    'Cruel',
    'Curious',
    'Cute',
    'Dangerous',
    'Dark',
    'Dead',
    'Defeated',
    'Defiant',
    'Delightful',
    'Depressed',
    'Determined',
    'Different',
    'Difficult',
    'Disgusted',
    'Distinct',
    'Disturbed',
    'Dizzy',
    'Doubtful',
    'Drab',
    'Dull',
    'Eager',
    'Easy',
    'Elated',
    'Elegant',
    'Embarrassed',
    'Enchanting',
    'Encouraging',
    'Energetic',
    'Enthusiastic',
    'Envious',
    'Evil',
    'Excited',
    'Expensive',
    'Exuberant',
    'Fair',
    'Faithful',
    'Famous',
    'Fancy',
    'Fantastic',
    'Fierce',
    'Filthy',
    'Fine',
    'Foolish',
    'Fragile',
    'Frail',
    'Frantic',
    'Friendly',
    'Frightened',
    'Funny',
    'Gentle',
    'Gifted',
    'Glamorous',
    'Gleaming',
    'Glorious',
    'Good',
    'Gorgeous',
    'Graceful',
    'Grieving',
    'Grotesque',
    'Grumpy',
    'Handsome',
    'Happy',
    'Healthy',
    'Helpful',
    'Helpless',
    'Hilarious',
    'Homeless',
    'Homely',
    'Horrible',
    'Hungry',
    'Hurt',
    'Ill',
    'Important',
    'Impossible',
    'Inexpensive',
    'Innocent',
    'Inquisitive',
    'Itchy',
    'Jealous',
    'Jittery',
    'Jolly',
    'Joyous',
    'Kind',
    'Lazy',
    'Light',
    'Lively',
    'Lonely',
    'Long',
    'Lovely',
    'Lucky',
    'Magnificent',
    'Misty',
    'Modern',
    'Motionless',
    'Muddy',
    'Mushy',
    'Mysterious',
    'Nasty',
    'Naughty',
    'Nervous',
    'Nice',
    'Nutty',
    'Obedient',
    'Obnoxious',
    'Odd',
    'Old-fashioned',
    'Open',
    'Outrageous',
    'Outstanding',
    'Panicky',
    'Perfect',
    'Plain',
    'Pleasant',
    'Poised',
    'Poor',
    'Powerful',
    'Precious',
    'Prickly',
    'Proud',
    'Puzzled',
    'Quaint',
    'Real',
    'Relieved',
    'Repulsive',
    'Rich',
    'Scary',
    'Selfish',
    'Shiny',
    'Shy',
    'Silly',
    'Sleepy',
    'Smiling',
    'Smoggy',
    'Sore',
    'Sparkling',
    'Splendid',
    'Spotless',
    'Stormy',
    'Strange',
    'Stupid',
    'Successful',
    'Super',
    'Talented',
    'Tame',
    'Tender',
    'Tense',
    'Terrible',
    'Testy',
    'Thankful',
    'Thoughtful',
    'Thoughtless',
    'Tired',
    'Tough',
    'Troubled',
    'Ugliest',
    'Ugly',
    'Uninterested',
    'Unsightly',
    'Unusual',
    'Upset',
    'Uptight',
    'Vast',
    'Victorious',
    'Vivacious',
    'Wandering',
    'Weary',
    'Wicked',
    'Wild',
    'Witty',
    'Worrisome',
    'Worried',
    'Wrong',
    'Zany',
    'Zealous',
];

const animals = [
    'Aphid',
    'Baboon',
    'Badger',
    'Beaver',
    'Beetle',
    'Bilby',
    'Blenny',
    'Bluejay',
    'Boa',
    'Bongo',
    'Bullfrog',
    'Bunny',
    'Camel',
    'Centaur',
    'Cheetah',
    'Chicken',
    'Chipmunk',
    'Chiton',
    'Cobra',
    'Dino',
    'Dolphin',
    'Donkey',
    'Dragon',
    'Eagle',
    'Emu',
    'Falcon',
    'Ferret',
    'Flytrap',
    'Gator',
    'Goby',
    'Groundhog',
    'Guppy',
    'Hedgehog',
    'Hippo',
    'Hornet',
    'Hydra',
    'Jackal',
    'Jaguar',
    'Kitten',
    'Kitty',
    'Kraken',
    'Leopard',
    'Lion',
    'Lizard',
    'Llama',
    'Lobster',
    'Manta',
    'Mantis',
    'Marmot',
    'Minnow',
    'Mollusk',
    'Monkey',
    'Muskox',
    'Mussel',
    'Mustang',
    'Osprey',
    'Ostrich',
    'Otter',
    'Oyster',
    'Paca',
    'Panda',
    'Panther',
    'Parrot',
    'Peacock',
    'Penguin',
    'Pigeon',
    'Pony',
    'Puggles',
    'Puppy',
    'Rabbit',
    'Raccoon',
    'Raptor',
    'Raven',
    'Rhino',
    'Scallop',
    'Sculpin',
    'Serpent',
    'Skua',
    'Spider',
    'Squirrel',
    'Stingray',
    'Swallow',
    'Swordfish',
    'Takin',
    'Tapir',
    'Tiger',
    'Titmouse',
    'Toucan',
    'Tuna',
    'Turkey',
    'Turtle',
    'Urchin',
    'Viper',
    'Walrus',
    'Weta',
    'Wombat',
    'Woodchuck',
    'Zebra',
];