import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { IStageModel } from '../models/StageModel';
import { Pane, IconButton, defaultTheme, Text } from 'evergreen-ui';
import Match from './Match';
import { VisualizationStyle } from '../common/Constants';
import { getRoundName } from '../common/EliminationUtils';


interface IStageEliminationProps {
    stage: IStageModel,
    showScores?: boolean,
    width?: number,
    displayStyle: VisualizationStyle,
}

const StageElimination: FC<IStageEliminationProps> = (props) => {
    const [selectedRound, setSelectedRound] = useState(1);
    const matchHeight = 80;
    const marginHeight = 8;
    let height = (matchHeight+(marginHeight*2)) / 4;
    
    return (
        <Pane overflowX='auto'>
            {props.stage.matches.map((group, i) => {
                return <Pane display='flex' key={'group_'+i}>
                    {group.map((round, j) => {
                        const showBrackets = j > 0 && j < group.length && (
                            (props.displayStyle === VisualizationStyle.Complete) || 
                            (props.displayStyle === VisualizationStyle.Compact && 
                                (j === selectedRound || j === selectedRound+1 || j === selectedRound-1 || j === selectedRound+2)
                            )
                        );

                        const showMatches = !(props.displayStyle === VisualizationStyle.Compact && 
                            j !== selectedRound && j!==selectedRound+1 && j!==selectedRound-1);

                        const showArrows = props.displayStyle === VisualizationStyle.Compact && j === selectedRound;
                        
                        if (showMatches || showBrackets) {
                            height *=2;
                        } else {
                            return '';
                        }
                        const borderStyle = j === selectedRound-1 || !showMatches ? 'dashed' : 'solid';
                        const bracketLength = 30;
                        return <Pane display='flex' flexDirection='column' key={'round_'+j} >
                            <Pane display='flex'alignItems='center' justifyContent='space-evenly' height={40} paddingLeft={bracketLength*2}>
                                {showArrows && <IconButton icon="arrow-left" 
                                    onClick={() => {setSelectedRound(selectedRound-1)}} 
                                    disabled={selectedRound < 2}/>}
                                {showMatches && <Text>{getRoundName(round.length)}</Text>}
                                {showArrows && <IconButton icon="arrow-right" 
                                    onClick={() => {setSelectedRound(selectedRound+1)}} 
                                    disabled={selectedRound > props.stage.matches[0].length-3}/>}
                            </Pane>
                            <Pane display='flex' flexDirection='column' justifyContent="space-around" height='100%'>
                            {round.map((match, k) => {
                                return <Pane marginY={8} key={match.id}
                                    display='flex' flexDirection='row' alignItems='center' 
                                    marginLeft={!showBrackets && props.displayStyle === VisualizationStyle.Compact? 60 : 0}>
                                    {showBrackets && <>
                                        <Pane  borderColor={defaultTheme.palette.neutral.light} 
                                            borderStyle={borderStyle} borderLeftStyle='hidden' 
                                            width={bracketLength} height={height}/>
                                        <Pane borderTopColor={defaultTheme.palette.neutral.light} 
                                            borderTopStyle={borderStyle}  width={bracketLength} height={1} />
                                    </>}
                                    {showMatches && 
                                        <Match match={match} 
                                            width={120} 
                                            showMatchNumber={true} 
                                            verticalDisplay={true} 
                                            showScores={!!props.showScores}/>
                                    }
                                </Pane>
                            })}
                            </Pane>
                        </Pane>
                    })}
                </Pane>
            })}
        </Pane>
    )
};

export default observer(StageElimination);