import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreProvider, createStore } from './models/RootModel';
import { onSnapshot } from 'mobx-state-tree';
import packageJson from '../package.json';

const storageName = packageJson.name

const rootStore = createStore(storageName);

onSnapshot(rootStore, snapshot => {
    localStorage.setItem(storageName, JSON.stringify(snapshot));
});

ReactDOM.render(
    <StoreProvider value={rootStore}>
        <App />
    </StoreProvider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
