import React, { FC, useState } from 'react';
import 'mobx-react-lite/batchingForReactDom';
import './App.css';
import { useStore } from './models/RootModel';
import { observer } from 'mobx-react';
import CompetitionCreation from './components/CompetitionCreation';
import { Pane, Heading, Button, SideSheet, Paragraph, Link, Strong } from 'evergreen-ui';


interface IAppProps {}

export const App: FC<IAppProps> = (props) => {
  const store = useStore();
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <Pane paddingLeft={20} display='flex' alignItems='center' borderBottom='default'>
        <img src="whistle.png" alt="logo" height={60} width={60} />
        <Heading size={500} marginLeft={15} marginRight='auto'>OWNLIGA</Heading>
        <Button appearance="minimal" alignSelf="right" onClick={() => setShowDrawer(true)}>
          About us
        </Button>
      </Pane>
      <CompetitionCreation competition={store.competition} />
      <SideSheet
        isShown={showDrawer}
        onCloseComplete={() => setShowDrawer(false)}
        width={400}
        shouldCloseOnOverlayClick={true}
        preventBodyScrolling
        containerProps={{
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
        }}>
          <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
              <Pane padding={16}>
                  <Heading size={600}>About us</Heading>
              </Pane>
          </Pane>
          <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
            <Paragraph marginTop="default">
              Welcome to OwnLiga.com, the place where you make the competition happen.
            </Paragraph>
            <Paragraph marginTop="default">
              We are currently a 1-man show, and the website has been developed actively.
            </Paragraph>
            <Paragraph marginTop="default">
              I have several ideas for the future features, but if you have any suggestions, 
              questions or comments, please let me know at 
              &nbsp;<Link href="mailto:help@ownliga.com" marginRight={12}><Strong>help@ownliga.com</Strong></Link>
            </Paragraph>

          </Pane>
        
      </SideSheet>
    </>
  );
}

export default observer(App);