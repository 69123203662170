import { types, Instance } from "mobx-state-tree"
import { CompetitionModel } from "./CompetitionModel";
import { createContext, useContext } from "react";
import { TeamModel } from "./TeamModel";
import { generateTeamsObjects } from "../common/Utils";
import * as firebase  from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import { firebaseConfig } from "../common/Constants";

firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'production') {
    firebase.analytics();
}

export const RootModel = types
    .model("RootModel", {
        competition: types.optional(CompetitionModel, {}),
        bye: types.optional(TeamModel, {id: "-1", name:'Bye'}),
    })
    .views(self => ({
        get storage(): firebase.firestore.Firestore {
            return firebase.firestore();
        }
    }))
    .actions(self => ({
        saveAll(snapshot: {competition:{}}) {
            console.log('saving everything');
            const compRef = self.storage.collection('competitions').doc(self.competition.id);
            compRef.update(snapshot.competition)
        }
    }));
;
    
export type IRootModel = Instance<typeof RootModel>

export const createStore = (storageName:string): IRootModel => {
    let initialState = {
        competition: {
            stages: [{
                name: 'Stage 1',
                teams: generateTeamsObjects(16),
            }]
        },
    };

    if (localStorage.getItem(storageName)) { 
        initialState = JSON.parse(localStorage.getItem(storageName)!);
    } else {
        localStorage.setItem(storageName, JSON.stringify(initialState))
    }

    const rootStore = RootModel.create(initialState);
    
    rootStore.competition.generateMatches();
    return rootStore;
};

const RootContext = createContext<IRootModel>({} as IRootModel);
export const useStore = () => useContext(RootContext);
export const StoreProvider = RootContext.Provider;