import React, { FC, useState } from 'react';
import { Pane, Heading, defaultTheme, UnorderedList, ListItem, Text } from 'evergreen-ui';
import { observer } from 'mobx-react';
import Match from './Match';
import { IMatchModel } from '../models/MatchModel';

const messages = (values:any[] = []) => ({
    roundI: `Round ${values[0]}`,
    matches: "Matches",
    matchesPerTeam: `Each team will play ${values[0]} matches.`,
    totalMatches: `This stage will have a total of ${values[0]} matches.`,
    noStageMatches: "No matches for this stage",
})
interface IRoundRobinMatchesProps {
    matches: Array<Array<IMatchModel>>,
    showScores?: boolean,
    width?: number,
    matchesTitle?: string,
}

const RoundRobinMatches: FC<IRoundRobinMatchesProps> = (props) => {
    const [selectedRound, setSelectedRound] = useState(1);

    const nRounds = props.matches.length || 0;

    const roundsListItems = () => {
        const items = [];
        for (let index = 1; index <= nRounds; index++) {
            if (selectedRound === index){
                items.push(<ListItem key={index} icon="arrow-right" color={defaultTheme.colors.text.selected} fontWeight={600}>{messages([index]).roundI}</ListItem>)
                continue;
            }
            items.push(<ListItem key={index} onClick={() => setSelectedRound(index)} cursor="pointer">{messages([index]).roundI}</ListItem>)
        }
        return items;
    }
    const matchesList = () => {
        if (!nRounds) {
        return <Pane margin={4}><Text>{messages().noStageMatches}</Text> </Pane>
        }
        return props.matches[selectedRound-1].map((match:any) => {
            return <Pane margin={4} key={match.id}><Match match={match} showScores={props.showScores} width={props.width}/></Pane>
        });
    }
    return (
        <Pane>
            <Pane display="flex">
                <Pane width={100}>
                    <UnorderedList size={300} padding={5}>
                        {roundsListItems()}
                    </UnorderedList>
                </Pane>
                <Pane marginLeft={20} marginTop={5}>
                    <Heading size={400}>{props.matchesTitle || messages().matches}</Heading>
                    {matchesList()}
                </Pane>
            </Pane>
        </Pane>
    )
};

export default observer(RoundRobinMatches);