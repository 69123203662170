import { types, Instance, IAnyModelType, getType } from "mobx-state-tree";
import uuid from "uuid";
import { TeamModel } from "./TeamModel";

export const MatchModel = types
    .model("MatchModel", {
        id: types.optional(types.identifier, () => uuid()),
        matchNumber: types.number,
        homeTeam: types.reference(
            types.union(
                types.late(() => TeamModel),
                types.late(():IAnyModelType => MatchModel)
            )
        ),
        homeTeamScore: types.optional(types.union(types.number, types.undefined), undefined),
        awayTeam: types.reference(
            types.union(
                types.late(() => TeamModel),
                types.late(():IAnyModelType => MatchModel)
            )
        ),
        awayTeamScore: types.optional(types.union(types.number, types.undefined), undefined),
    })
    .views(self => ({
        get homeTeamName(): string {
            if (getType(self.homeTeam).name === 'MatchModel') {
                return 'Match ' + self.homeTeam.matchNumber + ' winner';
            }
            return self.homeTeam.name;
        },
        get awayTeamName(): string {
            if (getType(self.awayTeam).name === 'MatchModel') {
                return 'Match ' + self.awayTeam.matchNumber + ' winner';
            }
            return self.awayTeam.name;
        }
    }))
    .actions(self => ({
        setHomeTeamScore(score: number) {
            self.homeTeamScore = score;
        },
        setAwayTeamScore(score: number) {
            self.awayTeamScore = score;
        },
    }));

;

export type IMatchModel = Instance<typeof MatchModel>