import { types, Instance, getParent } from "mobx-state-tree";
import uuid from "uuid";
import { firestore } from "firebase";
import { IStageModel } from "./StageModel";

export const TeamModel = types
    .model("TeamModel", {
        id: types.optional(types.identifier, () => uuid()),
        name: types.string,
    })
    .views(self => ({
        get storage(): firestore.Firestore {
            const stage: IStageModel = getParent(self,1);
            return stage.storage;
        },
    }))
    .actions(self => ({
        setName(newName:string) {
            self.name = newName;
        },
        storeTeam(batch:firestore.WriteBatch, parentRef: firestore.DocumentReference) {
            const teamRef = parentRef.collection('teams').doc(self.id);
            const thisBatch = batch || self.storage.batch();
            thisBatch.set(teamRef, {name: self.name});
            return;
        }
    }));
;

export type ITeamModel = Instance<typeof TeamModel>