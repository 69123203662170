import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { IStageModel } from '../models/StageModel';
import RounRobinMatches from './RounRobinMatches';

interface IStageRoundRobinProps {
    stage: IStageModel,
    showScores?: boolean,
    width?: number
}

const StageRoundRobin: FC<IStageRoundRobinProps> = (props) => {
    const allMatches = props.stage && props.stage.matches && props.stage.matches[0]
    return (
        <RounRobinMatches 
            matches={allMatches} 
            showScores={props.showScores}
            width={props.width}/>
    )
};

export default observer(StageRoundRobin);